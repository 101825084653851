export const GetData = async (airtableID: any) => {
    const apiUrl = `https://api.airtable.com/v0/${process.env.REACT_APP_AIRTABLE_BASE_ID}/${airtableID}?view=Grid%20view`;
  
    const data = await (
      await fetch(apiUrl, {
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_AIRTABLE_TOKEN}`,
        },
      })
    ).json();
  
    // set state when the data received
    return data.records;
  };