import Header from "../Header";
import { useEffect, useState } from "react";
import { imgRecord } from "../components/Types";
import { GetData, RandomizeArray } from "../utils";
import Loader from "../components/Loader/Loader";

const MasonryLayout = ({ items }: { items: any[] }) => {
  return (
    <div className="masonry">
      {items.map((item: any) => (
        <div key={item.id} className="masonry-item">
          {item.content}
        </div>
      ))}
    </div>
  );
};

function NewSite() {
  const [imgArray, setImgArray] = useState<imgRecord[]>();
  let allImgs: any[] = [];
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
  const fetchData = async () => {
    const data = await GetData(process.env.REACT_APP_ALL_TABLE);
    setImgArray(data);
    setIsLoading(false);
  };

  fetchData();
}, []);

imgArray?.forEach((imgRecord: imgRecord) => {
  imgRecord.fields.Attachments?.forEach((attachment: any) => {
    allImgs.push({ url: attachment.thumbnails.large.url, height: attachment.thumbnails.large.height });
  });
});
RandomizeArray(allImgs);
  return (
    <div className="App">
      <Header />
      {isLoading && <Loader />}
      <MasonryLayout
        items={allImgs.map((img: any, index: number) => ({
          id: index,
          height: img.height,
          content: <img src={img.url} alt="img" style={{ width: "100%" }} />,
        }))}
      />
    </div>
  );
}

export default NewSite;
