import Header from "../Header";
import { imgRecord } from "../components/Types";
import { useState, useEffect, Key } from "react";
import { GetData } from "../utils/GetData";
import Skeleton from "../components/Skeleton";

function Uncycled() {
  const [imgArray, setimgArray] = useState<imgRecord[]>();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // fetch data
    const dataFetch = async () => {
      setimgArray(await GetData(process.env.REACT_APP_AIRTABLE_UNCYCLED_ID));
      setIsLoading(false);
    };

    dataFetch();
  }, []);

  return (
    <div className="App">
      <Header />
      <div className="container">
        <div className="content">
          <h1>Volvo Cars Upcycled Collection</h1>
          <p>
            Products made of end of production leather from the cars. Brings the
            leather a new life & purpose which help people making a better
            choice.
          </p>
          {isLoading && <Skeleton />}
          <div className="product-grid">
            {imgArray &&
              imgArray.map(
                (imgRecord: imgRecord, index: Key | null | undefined) => {
                  console.log(imgRecord);
                  return (
                    <img
                      loading="lazy"
                      className="product"
                      src={imgRecord.fields.Attachments[0].url}
                      key={index}
                      alt={imgRecord.fields.alt}
                    />
                  );
                }
              )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Uncycled;
