import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import Work from "./pages/Work";
import About from "./pages/About";
import Contact from "./pages/Contact";
import Volvo from "./work-pages/Volvo";
import Icebug from "./work-pages/Icebug";
import Brandon from "./work-pages/Brandon";
import Uncycled from "./work-pages/Uncycled";
import NewSite from "./pages/NewSite";

import reportWebVitals from "./reportWebVitals";
import "./App.css";
import { createBrowserRouter, RouterProvider } from "react-router-dom";

const router = createBrowserRouter([
  {
    path: "/",
    element: <NewSite />,
  },
  {
    path: "/work",
    element: <Work />,
  },
  {
    path: "/about",
    element: <About />,
  },
  {
    path: "/contact",
    element: <Contact />,
  },
  {
    path: "/work/volvo",
    element: <Volvo />,
  },
  {
    path: "/work/icebug",
    element: <Icebug />,
  },
  {
    path: "/work/uncycled",
    element: <Uncycled />,
  },
  {
    path: "/work/brandon",
    element: <Brandon />,
  },
]);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
