import './loader.css';
const Loader = () => {
    return (
        <div className='loader-wrapper'>
      <span className="loader"/>
      </div>
    );
  }
  
  export default Loader;
  