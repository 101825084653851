import { Key, useEffect, useState } from "react";
import Header from "../Header";
import { GetData } from "../utils/GetData";
import { imgRecord } from "../components/Types";
import Skeleton from "../components/Skeleton";

function Volvo() {
  const [imgArray, setimgArray] = useState<imgRecord[]>();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // fetch data
    const dataFetch = async () => {
      setimgArray(await GetData(process.env.REACT_APP_AIRTABLE_VOLVO_ID));
      setIsLoading(false);
    };

    dataFetch();
  }, [isLoading]);

  return (
    <div className="App">
      <Header />
      <div className="container">
        <div className="content">
          <h1>Volvo Cars 2022/23</h1>
          <p>
            A range of products from the Volvo lifestyle collection, based on
            the theme "On the go".
          </p>
          {isLoading && <Skeleton />}
          <div className="product-grid">
            {imgArray &&
              imgArray.map(
                (imgRecord: imgRecord, index: Key | null | undefined) => {
                  return (
                    <img
                      loading="lazy"
                      className="product"
                      src={imgRecord.fields.Attachments[0].url}
                      key={index}
                      alt={imgRecord.fields.alt}
                    />
                  );
                }
              )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Volvo;
