import Header from "../Header";
import { Email } from "react-obfuscate-email";

function Contact() {
  return (
    <div className="App">
      <Header />
      <div className="container">
        <div className="contact">
          <p>
            <Email id="mail" email="frida.mathlin@gmail.com">
              @
            </Email>
            <br />
            +46 (0)702214405
          </p>
        </div>
      </div>
    </div>
  );
}

export default Contact;
