import { NavLink } from "react-router-dom";
import Header from "../Header";
import brandon from "../imgs/brandon.jpg";
import volvo from "../imgs/volvo.jpg";
import uncycled from "../imgs/uncycled.jpg";
import icebug from "../imgs/icebug.jpg";

function Work() {
  return (
    <div className="App">
      <Header />
      <div className="container">
        <div className="boxes">
          <NavLink to={"/work/volvo"}>
            <div className="work-card">
              <img alt="Volvo Lifestyle collection" src={volvo} />
            </div>
          </NavLink>
          <NavLink to={"/work/uncycled"}>
            <div className="work-card">
              <img alt="Volvo Uncycled collection" src={uncycled} />
            </div>
          </NavLink>
          <NavLink to={"/work/brandon"}>
            <div className="work-card">
              <img alt="Brandon internal branding" src={brandon} />
            </div>
          </NavLink>
          <NavLink to={"/work/icebug"}>
            <div className="work-card">
              <img alt="Icebug sketches" src={icebug} />
            </div>
          </NavLink>
        </div>
      </div>
    </div>
  );
}

export default Work;
